<template>
  <div class="work">
    <div class="container">
      <div class="nav">
        <div class="DWB" @click="toList">COHERE</div>
        <div class="DWB" @click="tohome">IS HERE</div>
      </div>
      <div class="works">
        <ul>
          <li v-for="item in list" :key="item.id" @click="toDetail(item.id)">
            <img :src="'https://www.coherehouse.com' + item.tmb1" alt="" />
            <div class="text">{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    // 获取数据
    const store = useStore()
    store.dispatch('article/getListAction')
    const list = computed(() => {
      return store.state.article.list
    })

    const router = useRouter()
    const tohome = () => {
      router.replace({
        name: 'mhome',
        query: {
          isback: 1
        }
      })
    }
    const toList = () => {
      router.replace({
        name: 'mhome',
        query: {
          isback: 1,
          top: 1
        }
      })
    }
    const toDetail = (index: number) => {
      router.replace({
        name: 'mworkdetail',
        params: {
          id: index
        }
      })
    }
    return { tohome, toList, toDetail, list }
  }
})
</script>
<style scoped lang="scss">
.work {
  width: 100%;
  background-color: #000;
  min-height: 100vh;
  padding-top: 100px;
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 40px 150px;
    .nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 30px;
      margin-bottom: 60px;
      > div {
        cursor: pointer;
      }
    }
    .works {
      width: 100%;
      font-size: 20px;
      letter-spacing: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      ul {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px 40px;
        align-items: start;
        li {
          cursor: pointer;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .text {
            margin-top: 18px;
            text-align: center;
            line-height: 1.3;
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
